<template>
  <div>
    <title-header :title="title" :buttons="headerButtons"></title-header>

    <v-row class="mr-5">
      <v-spacer></v-spacer>
      <v-col justify="end" md="4">
        <v-text-field v-model="search" class="ml-5" append-icon="mdi-magnify" label="Buscar" single-line clearable
          hide-details>
        </v-text-field>
      </v-col>
    </v-row>

    <v-data-table :headers="headers" :items="users" :search="search" class="elevation-1 pl-10 pr-10">
      <template v-slot:item.roles="{ item }">
        {{ item.roles.length > 1 ? 'Multiples' :
          item.roles.length === 0 ? 'N/A' : item.roles[0].name }}
      </template>

      <template v-slot:item.groups="{ item }">
        {{ item.groups.length > 1 ? 'Multiples' :
          item.groups.length === 0 ? 'N/A' : item.groups[0].name }}
      </template>

      <template v-slot:item.isActive="{ item }">{{ item.isActive ? 'Activo' : 'No activo' }}</template>

      <template v-slot:item.buttons="{ item }">
        <v-row justify="end">
          <v-btn v-if="item.session != null && item.id !== sessionUser.id" small rounded outlined class="mr-2"
            style="font-size: 10px" color="warning" @click="showRemoveSessionUserDialog(item)">Eliminar
            Sesión</v-btn>
          <v-btn small rounded outlined class="mr-2" color="primary"
            @click="routeToEditUser(item.id)"><v-icon style="font-size: 14px">mdi-pencil</v-icon></v-btn>
          <v-btn small rounded outlined class="mx-2" color="red" :disabled="item.id === sessionUser.id"
            @click="showRemoveUserDialog(item)"><v-icon style="font-size: 14px">mdi-delete</v-icon></v-btn>
        </v-row>
      </template>
    </v-data-table>

    <v-dialog v-model="deleteDialog" persistent max-width="480">
      <v-card>
        <v-card-title class="headline">¿Eliminar {{ selectedItem.name }}?</v-card-title>
        <v-card-text v-if="hasIssues" class="red--text font-weight-black">Este usuario tiene issues creados.</v-card-text>
        <v-card-text>¿Seguro que deseas eliminar este usuario?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="deleteDialog = false; hasIssues = false">Cancelar</v-btn>
          <v-btn color="primary darken-1" text @click="onRemoveUser(selectedItem)">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialogSession" persistent max-width="480">
      <v-card>
        <v-card-title class="headline">¿Eliminar sesión del usuario?</v-card-title>
        <v-card-text>¿Seguro que deseas eliminar la sesión de este usuario?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="deleteDialogSession = false;">Cancelar</v-btn>
          <v-btn color="primary darken-1" text @click="onRemoveSession(selectedItem)">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar color="primary" v-model="showSnackbar">
      {{ snackBarText }}
      <v-btn text @click="showSnackbar = false">Cerrar</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { UsersService } from '@/api/api.service'
import TitleHeader from '@/components/app/title-header/title-header'
import { USERS_GET_USERS, USERS_REMOVE_SESSION, USERS_REMOVE_USER } from '@/store/actions.type'
import { mapActions, mapGetters } from 'vuex'
import { mdiPencil } from '@mdi/js'

export default {
  name: 'users-list',
  props: {},
  data() {
    return {
      icons: {
        mdiPencil
      },
      headers: [
        { text: 'Nombre', value: 'displayName' },
        { text: 'Correo', value: 'email' },
        { text: 'Rol', value: 'roles' },
        { text: 'Grupo', value: 'groups' },
        { text: 'Estatus', value: 'isActive' },
        { text: '', value: 'buttons', width: 265 }
      ],
      search: '',
      deleteDialog: false,
      deleteDialogSession: false,
      showSnackbar: false,
      snackBarText: '',
      selectedItem: {},
      title: 'Usuarios',
      headerButtons: [
        {
          text: 'Nuevo usuario',
          urlSlug: 'users/create'
        },

        {
          text: 'Importar usuarios',
          urlSlug: 'users/import'
        }
      ],
      hasIssues: false
    }
  },
  computed: {
    ...mapGetters({
      users: 'getUsers',
      sessionUser: 'getSessionUser'
    })
  },
  methods: {
    ...mapActions({
      getUsers: USERS_GET_USERS,
      removeUser: USERS_REMOVE_USER,
      removeSession: USERS_REMOVE_SESSION
      // getMe: SESSION_GET_ME
    }),
    routeToEditUser(userId) {
      this.$router.push('users/' + userId)
    },
    showRemoveSessionUserDialog(item) {
      this.selectedItem = item
      this.deleteDialogSession = true
    },
    async showRemoveUserDialog(item) {
      const response = await UsersService.hasIssues(item)
      if (response.status === 200 && response.data.hasIssues) {
        this.hasIssues = true
      }

      this.selectedItem = item
      this.deleteDialog = true
    },
    onRemoveSession() {
      this.removeSession(this.selectedItem.session)
        .then(response => {
          this.hasIssues = false
          this.deleteDialogSession = false
          this.showSnackbar = true
          this.snackBarText = 'La sesión del usuario fue eliminada'
        })
        .catch(e => {
          this.hasIssues = false
          this.showSnackbar = true
          this.snackBarText = e.message
        })
    },
    onRemoveUser() {
      this.removeUser(this.selectedItem)
        .then(response => {
          this.hasIssues = false
          this.deleteDialog = false
          this.showSnackbar = true
          this.snackBarText = 'El usuario fue eliminado'
        })
        .catch(e => {
          this.hasIssues = false
          this.showSnackbar = true
          this.snackBarText = e.message
        })
    },
    showDeletedDialog() { }
  },
  components: {
    TitleHeader
  },
  mounted() {
    // this.getMe()
    this.getUsers()
  }
}
</script>

<style scoped>
table.v-table thead th {
  font-size: 40px;
}
</style>
